export const assetTypeLabel = {
    1:'MSN',
    2:'ESN',
    3:'APU',
    4:'LG',
    5:'Propeller',
    6:'FAN',
    7:'HPC',
    8:'HPT',
    9:'LPT'
}
export const assetTypeKey = {
    0:'all',
    1:'aircraft',
    2:'engine',
    3:'apu',
    4:'lg',
    5:'propeller',
    6:'fan',
    7:'hpc',
    8:'hpt',
    9:'lpt'
}
export const assetValueKey = {
    'all':0,
    'aircraft':1,
    'engine':2,
    'apu':3,
    'lg':4,
    'propeller':5,
    'fan':6,
    'hpc':7,
    'hpt':8,
    'lpt':9
}
export const majorAssemblyFilters = {
    'serial_number':{
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'aircraft_type':{
        'inputType': 'dropdown',
        'title': 'Aircraft Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'engine_type':{
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },

    'registration':{
        'inputType': 'text',
        'title': 'Registration',
    }
};
export const assetFilterOptions = {
    'serial_number':{
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'aircraft_type':{
        'inputType': 'dropdown',
        'title': 'Aircraft Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'engine_type':{
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'apu_type':{
        'inputType':'dropdown',
        'title':'APU Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'lessee':{
        'inputType': 'dropdown',
        'title': 'Lessee',
        'options':[],
        'labelKey':'name',
        'valueKey':'slug'
    },
    'ownership_type':{
        'inputType': 'dropdown',
        'title': 'Ownership',
        'options':[{label: 'Owned', value: '1'}, {label: 'Managed', value: '2'}],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':true
    },
    'status':{
        'inputType': 'dropdown',
        'title': 'Status',
        'options':[],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':true
    },
    'sub_status':{
        'inputType': 'dropdown',
        'title': 'Sub Status',
        'options':[],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':true
    },
    'lessor_name':{
        'inputType': 'text',
        'title': 'Lessor',
    },
    'portfolio':{
        'inputType': 'text',
        'title': 'Portfolio',
    },
    'registration':{
        'inputType': 'text',
        'title': 'Registration Number',
    },
    'owner':{
        'inputType': 'text',
        'title': 'Owner',
    },
}

export const leaseStatus = {
    jacLeaseStatus: [{ label: 'Prospect', value: '11' }, { label: 'In Storage', value: '10' }, { label: 'Lease Return', value: '9' }, { label: 'MOU Signed', value: '8' }, { label: 'MOU Issued', value: '7' }, { label: 'Lease Issued', value: '6' }, { label: 'Archive', value: '5' }, { label: 'Written Off/ Sold / Part out', value: '4' },
    { label: 'Off Lease', value: '2' }, { label: 'On Lease', value: '1' },],
    defaultLeaseStatus: [{ label: 'Archive', value: '5' }, { label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },],
    airAsiaLeaseStatus: [{ label: 'Prospect', value: '11' }]
}
export const inventoryObj = {
    description:'',
    serial_number:'',
    part_number:'',
    owner:'',
    location:'',
    linked_asset:'',
    status:'',
    files:[]
}
export const inventoriesFilters = {
    'description':{
        'inputType': 'text',
        'title': 'Description',
    },
    'serial_number':{
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'part_number':{
        'inputType': 'text',
        'title': 'Part Number',
    },
    'owner':{
        'inputType': 'text',
        'title': 'Owner',
    },
    'location':{
        'inputType': 'text',
        'title': 'Location',
    },
    'linked_asset':{
        'inputType': 'text',
        'title': 'Linked Asset',
    },
    'status':{
        'inputType': 'text',
        'title': 'Status',
    }
}
export const notificationFilter={
    'status':{
        'inputType': 'dropdown',
        'title': 'Status',
        'options':[{label:'Read', value:1}, {label:'UnRead', value:2}],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':false
    },
    'start_date': {
        'inputType': 'date',
        'keyParam': 'start_date',
        'title':'Start Date'
    },
    'end_date': {
        'inputType': 'date',
        'keyParam': 'end_date',
        'title':'End Date'
      },
}